export enum PRACTICE_TYPE {
  '' = 'All',
  internship = 'Internship',
  practice = 'Practice',
  holiday_internship = 'Holiday Internship',
  'internship,practice' = 'Practice & Internship',
}

export enum WORK_MODEL {
  '' = 'All',
  office = 'Office',
  remote = 'Remote',
  hybrid = 'Hybrid',
}

export enum SALARY_TYPE {
  hourly = 'hourly',
  monthly = 'monthly',
}

export enum IS_PAID {
  '' = 'All',
  true = 'Yes',
  false = 'No',
}
