import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import { css } from '@emotion/react';

export const Offer = styled.a`
  position: relative;
  display: block;
  background-color: #fff;
  padding: 24px;
  min-height: 250px;
  border-radius: 3px;
  box-shadow: 0 4px 4px 4px rgba(214, 214, 214, 0.2);
  min-width: 412px;
  flex: 1;
  text-decoration: none;
  cursor: pointer;

  @media only screen and ${breakpoint.md} {
    min-width: unset;
    width: 100% !important;
    flex: 0 0 100% !important;
    margin: 10px auto;
  }

  & h3 {
    margin: 16px 0 24px;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }
`;

export const OfferBoxHeader = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Col = styled.div<{ alignRight?: boolean }>`
  flex: 1 0 50%;

  ${({ alignRight }) =>
    alignRight
      ? css`
          text-align: right;
        `
      : null}
`;

export const EmployerLogo = styled.div`
  width: auto;
  height: 60px;
  position: relative;
`;

export const CompanyName = styled.p`
  font-size: ${fontSize(18)};
  text-transform: uppercase;
  line-height: 1.2;
  color: #00202d;
  margin-top: 14px;
`;

export const ExpirationDate = styled.p`
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: ${fontSize(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  text-align: right;
  color: #00405b;
`;

export const PreloaderContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  & div {
    top: calc(50% - 20px);
    left: 50%;
    transform: translateX(-10px);
  }
`;

export const OfferName = styled.h3`
  font-size: ${fontSize(18)};
  font-weight: 700;
  color: #00405b;
`;

export const EditButton = styled.button`
  position: absolute;
  bottom: 14px;
  left: 14px;
  border: none;
  background-color: transparent;
  padding: 10px;
  z-index: 5;
  cursor: pointer;
`;

export const RemoveButton = styled.button`
  position: absolute;
  bottom: 14px;
  left: 48px;
  border: none;
  background-color: transparent;
  padding: 10px;
  z-index: 5;
  cursor: pointer;
`;
