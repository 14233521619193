import React, { memo, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';

import { prepareLink } from '@utils/dataHelpers';
import {
  Offer,
  OfferBoxHeader,
  Col,
  EmployerLogo,
  CompanyName,
  ExpirationDate,
  PreloaderContainer,
  OfferName,
  EditButton,
  RemoveButton,
} from '@components/OfferBox/styles';
import { OfferTypeLabel, WorkTypeLabel } from '@styles/globalStyles';
import Preloader, { POSITIONS } from '@components/Preloader';
import PencilIcon from '@assets/images/ic-edit.svg';
import TrashIcon from '@assets/images/icons/trash.svg';

interface OfferBoxProps {
  offer: OfferBox;
  isFetching?: boolean;
  editable?: boolean;
  deletable?: boolean;
  onDeleteClick?: (id: number) => void;
  onEditClick?: (slug: string) => void;
}

function OfferBox({ offer, isFetching, editable, deletable, onEditClick, onDeleteClick }: OfferBoxProps) {
  const { t } = useTranslation('offers');

  const editButtonClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onEditClick === 'function') {
        onEditClick(offer.slug);
      }
    },
    [offer.slug, onEditClick]
  );

  const deleteButtonClick = useCallback(
    (ev) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(t('Confirm Delete Offer'))) {
        ev.stopPropagation();
        ev.preventDefault();
        if (typeof onDeleteClick === 'function') {
          onDeleteClick(offer.id);
        }
      }
    },
    [offer.id, t, onDeleteClick]
  );
  return (
    <Link href={prepareLink('offer', offer.slug, offer.id)} passHref>
      <Offer className="offer-box" href={prepareLink('offer', offer.slug, offer.id)}>
        {isFetching ? (
          <PreloaderContainer className="siema">
            <Preloader position={POSITIONS.ABSOLUTE} />
          </PreloaderContainer>
        ) : (
          <>
            <OfferBoxHeader>
              <Col>
                <OfferTypeLabel type={offer.practiceType}>{t(offer.practiceType)}</OfferTypeLabel>
                <CompanyName>{offer.employerProfile.name}</CompanyName>
                <WorkTypeLabel type={offer.workModel}>{t(offer.workModel)}</WorkTypeLabel>
              </Col>
              <Col alignRight>
                <EmployerLogo>
                  {offer.employerProfile.logo.desktop ? (
                    <Image
                      src={offer.employerProfile.logo.desktop}
                      alt={offer.employerProfile.name}
                      width="100%"
                      height={80}
                      objectFit="contain"
                    />
                  ) : null}
                </EmployerLogo>
              </Col>
            </OfferBoxHeader>

            <OfferName>{offer.name}</OfferName>

            {editable ? (
              <EditButton type="button" onClick={editButtonClick}>
                <Image src={PencilIcon} alt="" />
              </EditButton>
            ) : null}
            {deletable ? (
              <RemoveButton type="button" onClick={deleteButtonClick}>
                <Image src={TrashIcon} alt="" />
              </RemoveButton>
            ) : null}
            {offer.stopRecruitment ? (
              <ExpirationDate>
                {t('Expires')} {offer.stopRecruitment}
              </ExpirationDate>
            ) : null}
          </>
        )}
      </Offer>
    </Link>
  );
}

OfferBox.defaultProps = {
  isFetching: false,
  editable: false,
  deletable: false,
  onEditClick: null,
  onDeleteClick: null,
};

export default memo(OfferBox);
