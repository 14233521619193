import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { fontSize } from '@styles/globalStyles';
import { standaloneDropdownContainer } from '@components/Form/SimpleDropdown/styles';

export const Container = styled.div`
  position: relative;

  .preloader {
    bottom: 4px;
    right: 55px;
  }

  ${({ theme, type }) =>
    type === 'standalone'
      ? standaloneDropdownContainer(theme)
      : css`
          position: relative;
          width: ${type === 'horizontal' ? '50%' : '100%'};
          &:nth-of-type(2n) {
            padding-left: ${type === 'horizontal' && '16px'};

            @media only screen and (max-width: 563px) {
              padding-left: 0;
            }
          }

          &:nth-of-type(2n + 1) {
            padding-right: ${type === 'horizontal' && '16px'};

            @media only screen and (max-width: 563px) {
              padding-right: 0;
            }
          }

          @media only screen and (max-width: 563px) {
            width: 100%;
          }
        `}
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: #00202d;
  position: relative;
`;

export const ErrorText = styled.p`
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.borders.invalid};
  position: absolute;
  top: 65px;
`;

export const PreloaderWrapper = styled.div`
  position: absolute;
  right: 35px;
  bottom: 5px;
  z-index: 2;
  pointer-events: none;
  width: 30px;
  height: 30px;
`;

export const ReactSelectStyles = {
  container: (provided) => ({
    ...provided,
    marginBottom: 30,
    marginTop: 5,
    textAlign: 'left',
    borderColor: '#ccecf9',
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  dropdownIndicator: (provided, state) => {
    return {
      color: '#009fe3',
      marginTop: state.selectProps.menuIsOpen ? 3 : 5,
      marginRight: 10,
      transition: 'all 0.3s ease-in-out',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
    };
  },
  clearIndicator: (provided) => ({
    ...provided,
    marginTop: 3,
    paddingBottom: 5,
    color: 'hsl(0,0%,80%)',
    '&:hover': {
      color: 'hsl(0,0%,80%)',
    },
  }),
  option: (provided) => ({
    ...provided,
    padding: 10,
    width: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #ccecf9',
    minHeight: '40px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #ccecf9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000000',
    height: '100%',
  }),
  input: (provided) => ({
    ...provided,
    margin: '-1px',
    paddingBottom: 0,
    paddingTop: 0,
  }),
};
