import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { useTranslation } from 'next-i18next';
import { FieldError } from 'react-hook-form';
import { useRouter } from 'next/router';

import {
  Container,
  Label,
  ErrorText,
  PreloaderWrapper,
  ReactSelectStyles,
  SelectWrapper,
} from '@components/Form/Dropdown/styles';
import {
  customStyles as StandaloneDropdownStyles,
  Label as StandaloneLabel,
} from '@components/Form/SimpleDropdown/styles';
import Preloader from '@components/Preloader';
import { setApiLocale } from '@utils/api';

type AsyncDropdownTypes = {
  loadOptions: () => Promise<OptionType[]>;
  onChange: (option: number | string) => void;
  label: string;
  error?: FieldError;
  placeholder?: string | null;
  value: string | number | null;
  name: string;
  queryName?: string;
  id?: string | null;
  translationKey?: string;
  type?: 'horizontal' | 'standalone' | 'vertical';
  withNullValue?: boolean;
};

function AsyncDropdown({
  loadOptions,
  onChange,
  label,
  error,
  placeholder,
  value,
  name,
  queryName,
  id,
  translationKey,
  type,
  withNullValue,
}: AsyncDropdownTypes) {
  const { locale } = useRouter();
  const [previousLocale, setPreviousLocale] = useState(null);

  const { t } = useTranslation('common');
  const selectId = `dropdown_${name}-${id}`;
  const {
    data: options,
    refetch,
    isFetching,
  } = useQuery(`${name}_options`, loadOptions, {
    initialData: [],
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const fetchOptions = useCallback(async () => {
    if (!options.length || !previousLocale) {
      await refetch();
    }
    setPreviousLocale(locale);
  }, [refetch, options, locale, previousLocale]);

  const optionLabelFormatter = useCallback(
    (option) => {
      if (translationKey) {
        return t(`${translationKey}:${option.name}`);
      }

      return option.name;
    },
    [translationKey, t]
  );

  useEffect(() => {
    if ((value && !options.length) || previousLocale !== locale) {
      (async () => {
        setApiLocale(locale);

        await refetch();
      })();
    }
  }, [value, options.length, refetch, locale, previousLocale]);

  const selectedOption = withNullValue
    ? options.find((x) => x.id === value) || options[0]
    : options.find((x) => x.id === value);

  return (
    <Container type={type}>
      {type === 'standalone' ? (
        <StandaloneLabel htmlFor={selectId}>{label}</StandaloneLabel>
      ) : (
        <Label htmlFor={selectId}>{label}</Label>
      )}

      <SelectWrapper>
        {isFetching ? (
          <PreloaderWrapper className="preloader">
            <Preloader />
          </PreloaderWrapper>
        ) : null}
        <Select
          id={selectId}
          options={options}
          name={name}
          classNamePrefix="react-select"
          isClearable
          escapeClearsValue
          backspaceRemovesValue
          onChange={(option: OptionType) => {
            onChange(option?.id || '');
          }}
          onFocus={fetchOptions}
          styles={type === 'standalone' ? StandaloneDropdownStyles : ReactSelectStyles}
          getOptionLabel={optionLabelFormatter}
          getOptionValue={(option: OptionType) => option.id.toString()}
          placeholder={isFetching ? t('Loading') : placeholder}
          value={selectedOption}
          noOptionsMessage={() => null}
          maxMenuHeight={300}
        />
      </SelectWrapper>

      {error && <ErrorText className="error-message">{error.message}</ErrorText>}
    </Container>
  );
}

AsyncDropdown.defaultProps = {
  placeholder: null,
  id: null,
  translationKey: null,
  type: null,
  error: null,
  withNullValue: false,
  queryName: null,
};

export default AsyncDropdown;
